






































































import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import Drawer from '@/components/common/Drawer.vue'
import { ShopsListItemType } from '@/types/shops'
@Component({
  components: { Drawer },
})
export default class ShopInfo extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: Object })
  shopInfo!: ShopsListItemType

  @Prop({ type: Boolean })
  loading?: boolean

  handleCloseShopInfo(): void {
    this.$emit('change')
  }

  handleMemberInfoShow(member): void {
    this.$emit('show-member-info', member.ID)
  }
}
